import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {injectIntl} from "react-intl"
import TextField from '@mui/material/TextField'
import {refetchTalent, updateTalent} from '../../../redux/actions/Talent'
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import LeftAside from "../../../components/Talent/leftAside";
import {Link} from 'react-router-dom';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import MomentUtils from "@date-io/moment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {capitalizeFirstLetter} from "../../../utils/format";
import {graphqlTalentFields} from "../../../utils/constant"
import AutocompleteCreatable from "../../../components/common/AutocompleteCreatable"
import moment from "moment-timezone";
import {pageLayoutMaxWidth} from "../../../utils/nav_company";
import PageTitle from "../../../components/common/PageTitle";
import ValidateButton from "../../../components/common/ValidateButton";
import AutocompleteText from "../../../components/common/AutocompleteText";
import {autoRef, track} from "../../../redux/actions/Ref";
import { updateContactPhone } from '../../../redux/actions/Company';

const PersonalData = ({intl}) => {
    const dispatch = useDispatch()

    const {token, user} = useSelector(({auth}) => auth)
    const {countries, genders} = useSelector(({list}) => list)
    const {talent} = useSelector(({talent}) => talent)
    const {refSuccessData} = useSelector(({ref}) => ref)
    const [formTalent, setFormTalent] = useState({})
    const {locale} = useSelector(({common}) => common)

    useEffect(() => {
        if (talent) {
            dispatch(refetchTalent())
        }
    }, [])

    useEffect(() => {
        dispatch(track(token, 101))
    },[])

    useEffect(() => {
        if (talent) {
            setFormTalent({
                gender_id: talent.gender_id,
                date_of_birth: talent.date_of_birth ? moment(talent.date_of_birth, 'YYYY-MM-DD') : null,
                nationality_code: talent.nationality_code,
                address_country_code: talent.address_country_code,
                address_zip_code: talent.address_zip_code,
                city: talent.city,
                address_city_id: talent.address_city_id,
                address_street_1: talent.address_street_1,
                address_street_2: talent.address_street_2,
                phone_prefix: talent?.user?.phone_prefix ?? '',
                phone: talent?.user?.phone ?? ''
            })
        }
    }, [talent])

    const reformatCountryPhone = (phone_prefix) => {
        let option = '';
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].phone_prefix === phone_prefix) {
                option = countries[i];
                break;
            }
        }

        return option
    }
    const onUpdateContactPhone = () => {
        dispatch(updateContactPhone(token, {
            ...formTalent
        }, false, intl))
    }
    const handleTextChangePhone = (value, field) => {
        setFormTalent({
            ...formTalent,
            [field]: value
        })
    }

    useEffect(() => {
        if (refSuccessData) {
            dispatch(updateTalent(token, `
            mutation {
                    upsertTalent(
                        id: "${user.relation}", 
                        ${refSuccessData?.localValues?.fieldName}: "${refSuccessData?.data?.id}", 
                    ) {
                    ${graphqlTalentFields}
                }
            }
        `, false,false,intl,"coordonées"))
        }
    }, [refSuccessData])

    const handleTextChange = (value, field) => {
        setFormTalent({
            ...formTalent,
            [field]: value
        })
    }

    const handleSelectChange = (value, field) => {
        setFormTalent({
            ...formTalent,
            [field]: value
        })
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [open, setOpen] = useState(false);

    const handleCityChange = (value) => {
        dispatch(
            autoRef(token, locale, {
                "type": "talent_city",
                "name": value,
                "table": "ref_cities",
                "dependencies": {
                    "talent_id": talent?.id
                }
            })
        )
    }

    const onUpdateTalent = () => {
        let gender_id = formTalent?.gender_id ? `gender_id: "${formTalent?.gender_id}",` : '';
        let date_birth = formTalent?.date_of_birth ? `date_of_birth: "${formTalent?.date_of_birth?.format('YYYY-MM-DD')}",` : '';
        let nationality_code = formTalent?.nationality_code ? `nationality_code: "${formTalent?.nationality_code}",` : '';
        let address_country_code = formTalent?.address_country_code ? `address_country_code: "${formTalent?.address_country_code}",` : '';
        let address_zip_code = formTalent?.address_zip_code ? `address_zip_code: "${formTalent?.address_zip_code}",` : '';
        let address_city_id = formTalent?.address_city_id ? `address_city_id: "${formTalent?.address_city_id}",` : '';
        let address_street_1 = formTalent?.address_street_1 ? `address_street_1: "${formTalent?.address_street_1}",` : '';
        let address_street_2 = formTalent?.address_street_2 ? `address_street_2: "${formTalent?.address_street_2}"` : '';

        dispatch(updateTalent(token, `
            mutation {
                    upsertTalent(
                        id: "${user.relation}"
                        ${gender_id} 
                        ${date_birth}  
                        ${nationality_code}  
                        ${address_country_code} 
                        ${address_zip_code} 
                        ${address_street_1} 
                        ${address_street_2} 
                    ) {
                    ${graphqlTalentFields}
                }
            }
        `, false,false,intl,"coordonées"))
    }

    const reformatCountry = (countryCode) => {
        let option = '';
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].code === countryCode) {
                option = countries[i];
                break;
            }
        }

        return option
    }


    const renderDisabled = () => {
        return (
            <div className={'mb-8'}>
                <div className="flex flex-col space-y-4 md:flex-row md:space-x-8 md:space-y-0">
                    <section className="flex-1 p-2 flex flex-col">
                        <div>
                            <div className="relative">
                                <input id="talent-id"
                                       className="block px-5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-full border-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-not-allowed font-bold border-sky-200"
                                       type="text" value={talent?.first_name} disabled="disabled"
                                       placeholder=" "
                                       autoComplete="off" name="talent-id"/>
                                <label htmlFor="talent-id"
                                       className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2  peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-4">
                                    {intl.formatMessage({id: 'last.name'})}
                                </label>
                            </div>
                        </div>
                    </section>
                    <section className="flex-1 p-2 flex flex-col">
                        <div>
                            <div className="relative">
                                <input id="talent-id"
                                       className="block px-5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-full border-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-not-allowed font-bold border-sky-200"
                                       type="text" value={talent?.last_name} disabled="disabled"
                                       placeholder=" "
                                       autoComplete="off" name="talent-id"/>
                                <label htmlFor="talent-id"
                                       className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2  peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-4">
                                    {intl.formatMessage({id: 'first.name'})}
                                </label>
                            </div>
                        </div>
                    </section>
                    <section className="flex-1 p-2 flex flex-col">
                        <div>
                            <div className="relative">
                                <input id="talent-id"
                                       className="block px-5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-full border-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-not-allowed font-bold border-sky-200"
                                       type="text" value={user?.email} disabled="disabled" placeholder=" "
                                       autoComplete="off" name="talent-id"/>
                                <label htmlFor="talent-id"
                                       className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2  peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-4">
                                    {intl.formatMessage({id: 'email'})}
                                </label>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="flex  flex-col space-y-4 md:flex-row md:space-x-8 md:space-y-0 !mt-4 md:!mt-0">
                    <section className="flex-1 p-2 flex flex-col">
                        <div>
                            <div className="relative">
                                <input id="talent-id"
                                    className="block px-5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-full border-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-not-allowed font-bold border-sky-200"
                                    type="text" value={user?.phone_prefix + " " + user?.phone}
                                    disabled="disabled"
                                    placeholder=" " autoComplete="off" name="talent-id" />
                                <label htmlFor="talent-id"
                                    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2  peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-4">
                                    {intl.formatMessage({ id: 'portable.phone' })}
                                </label>
                            </div>
                        </div>
                    </section>
                    <section className="flex-1 p-2 flex flex-col">
                        <div>
                            <div className="relative">
                                <input id="talent-id"
                                       className="block px-5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-full border-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-not-allowed font-bold border-sky-200"
                                       type="text" value={talent?.identifier} disabled="disabled"
                                       placeholder=" "
                                       autoComplete="off" name="talent-id"/>
                                <label htmlFor="talent-id"
                                       className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2  peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-4">
                                    {intl.formatMessage({id: 'identifier'})}
                                </label>

                            </div>

                        </div>
                    </section>
                    <section className="flex-1 p-2 flex flex-col">
                        <div>
                            <div className="relative">
                                <input id="talent-pseudonym"
                                       className="block px-5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-full border-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-not-allowed font-bold border-sky-200"
                                       type="text" disabled="disabled" value={talent?.pseudonym}
                                       placeholder=" " autoComplete="off" name="pseudonym" required=""/>
                                <label htmlFor="talent-pseudonym"
                                       className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2  peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-4">
                                    {intl.formatMessage({id: 'pseudonym'})} *
                                </label>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

    return (
        <div className="flex  justify-center grow">
            <div style={{maxWidth: pageLayoutMaxWidth}} className="flex flex-col px-5 w-full">
                <div className={'flex space-x-6'}>
                    <div className={'flex flex-col flex-grow'}>

                        <PageTitle title={'my.personal.data'} />

                        <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
                            <div className={'flex-1 flex flex-col  p-10 space-y-4 shadow-xxl rounded-4xl'}>
                                <div className={'flex-1'}>
                                    <FormControl className={'w-full'}>
                                        <InputLabel
                                            id="demo-simple-select-standard-label">{intl.formatMessage({id: 'gender'})}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={formTalent?.gender_id || ''}
                                            label={intl.formatMessage({id: 'gender'})}
                                            onChange={(e) => handleTextChange(e.target.value, 'gender_id')}
                                            onBlur={onUpdateTalent}
                                            className={'text-left'}
                                        >
                                            {genders?.map(option => {
                                                return (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {capitalizeFirstLetter(option.name)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className={'flex-1'}>
                                    <LocalizationProvider dateAdapter={MomentUtils}>
                                        <DatePicker
                                            disableFuture
                                            label={intl.formatMessage({id: 'date_of_birth'})}
                                            views={['year', 'month', 'day']}
                                            value={formTalent?.date_of_birth}
                                            onChange={(newValue) => {
                                                handleTextChange(newValue, 'date_of_birth')
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            className={'w-full'}
                                            inputFormat={"DD/MM/YYYY"}
                                            openTo="year"
                                            closeOnSelect={false}  /* DO NOT REMOVE THIS OR FEAR ALL HELL TO DESCEND OF YOU. I DONT KNOW WHY IT DOES NOT WORK WITHOUT THIS*/
                                            onClose={onUpdateTalent}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className={'flex-1'}>
                                    <Autocomplete
                                        value={formTalent?.nationality_code ? reformatCountry(formTalent?.nationality_code) : null}
                                        disablePortal
                                        id="combo-box-demo"
                                        options={countries}
                                        getOptionLabel={(option) => capitalizeFirstLetter(option?.name)}
                                        isOptionEqualToValue={(option, value) => option.code === value}
                                        onChange={(event, newValue) => {
                                            handleSelectChange(newValue.code, 'nationality_code')
                                        }}
                                        onBlur={onUpdateTalent}
                                        className={'rounded-full'}
                                        renderInput={(params) => <TextField {...params}
                                                                            label={intl.formatMessage({id: 'country.of.birth'})}/>}
                                    />
                                </div>
                                <div className={'flex-1'}>
                                    <Autocomplete
                                        value={formTalent?.address_country_code ? reformatCountry(formTalent?.address_country_code) : ''}
                                        disablePortal
                                        id="combo-box-demo"
                                        options={countries}
                                        getOptionLabel={(option) => capitalizeFirstLetter(option.name)}
                                        isOptionEqualToValue={(option, value) => option.code === value}
                                        onChange={(event, newValue) => {
                                            handleSelectChange(newValue.code, 'address_country_code')
                                        }}
                                        onBlur={onUpdateTalent}
                                        className={'rounded-full w-full'}
                                        renderInput={(params) => <TextField {...params}
                                            label={intl.formatMessage({ id: 'country.of.residence' })} />}
                                        disabled={true}
                                    />
                                </div>
                                <div className={'flex-1'}>
                                    <Autocomplete
                                        value={formTalent?.phone_prefix ? reformatCountryPhone(formTalent?.phone_prefix) : null}
                                        disablePortal
                                        id="phone-prefix-autocomplete"
                                        options={countries}
                                        getOptionLabel={(option) => `${capitalizeFirstLetter(option?.name)} (${option?.phone_prefix})`}
                                        isOptionEqualToValue={(option, value) => option.phone_prefix === value.phone_prefix}
                                        onChange={(e, newValue) => {
                                            setFormTalent((prev) => ({
                                                ...prev,
                                                phone_prefix: newValue ? newValue.phone_prefix : '',
                                            }));
                                        }}
                                        onBlur={onUpdateContactPhone}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label={intl.formatMessage({ id: 'phone_prefix' })}
                                            />
                                        }
                                    />

                                </div>
                            </div>
                            <div className={'flex-1 flex flex-col  p-10 space-y-4 shadow-xxl rounded-4xl'}>
                                <div className={'flex-1'}>
                                    <TextField
                                        fullWidth
                                        id="talent-whatsapp-number"
                                        label={intl.formatMessage({id: 'address_1'})}
                                        value={formTalent?.address_street_1 || ''}
                                        onChange={(e) => handleTextChange(e?.target?.value, 'address_street_1')}
                                        onBlur={onUpdateTalent}
                                    />
                                </div>
                                <div className={'flex-1'}>
                                    <TextField
                                        fullWidth
                                        id="talent-whatsapp-number"
                                        label={intl.formatMessage({id: 'address_2'})}
                                        value={formTalent?.address_street_2 || ''}
                                        onChange={(e) => handleTextChange(e?.target?.value, 'address_street_2')}
                                        onBlur={onUpdateTalent}
                                    />
                                </div>
                                <div className={'flex-1'}>
                                    <TextField
                                        fullWidth
                                        id="talent-whatsapp-number"
                                        label={intl.formatMessage({id: 'zip_code'})}
                                        value={formTalent?.address_zip_code || ''}
                                        onChange={(e) => handleTextChange(e?.target?.value, 'address_zip_code')}
                                        onBlur={onUpdateTalent}
                                    />
                                </div>
                                <div className={'flex-1'}>
                                    <AutocompleteText
                                        label={intl.formatMessage({id: 'city'})}
                                        tableName="ref_cities"
                                        value={formTalent?.city?.name}
                                        onValueChange={handleCityChange}
                                        disableClearable={true}
                                    />
                                </div>
                                <div className={'flex-1'}>
                                    <TextField
                                        label={intl.formatMessage({ id: "phoneNumber" })}
                                        className={'w-full no-arrow'}
                                        value={formTalent?.phone}
                                        id="formatted-numberformat-input"
                                        onChange={(e) => handleTextChangePhone(e?.target?.value, 'phone')}
                                        onBlur={onUpdateContactPhone}
                                        InputLabelProps={{ shrink: !!formTalent?.phone || formTalent?.phone === '' }}
                                    />
                                </div>

                                {/* <div className={'flex-1'}>
                                    <FetchableMultiple
                                        label={intl.formatMessage({ id: 'city' })}
                                        tableName="ref_cities"
                                        value={test}
                                        onValueChange={handleTestChange}
                                    />
                                </div> */}
                            </div>
                        </div>

                        {/* <ValidateButton /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(PersonalData)
