import {CREATE_REF_SUCCESS, RESET_REF_SUCCESS, SET_REF_STATE,} from '../action-types'

const INIT_STATE = {
    refSuccessData: null,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_REF_SUCCESS: {
            return {
                ...state, refSuccessData: {
                    data: action?.data,
                    localValues: action?.localValues,
                }
            }
        }

        case RESET_REF_SUCCESS: {
            return {
                ...state,
                refSuccessData: null,
            }
        }

        case SET_REF_STATE: {
            return {
                ...state,
                ...action?.payload
            }
        }

        default:
            return state
    }
}
