import {axiosInstance} from '../../axios/axios'

export const updateEduExpCertRequest = async (token, apiValues) =>
    await axiosInstance.post('/api/talent/edu_exp_cert', apiValues, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "multipart/form-data",
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateSkillLangHobbyRequest = async (token, apiValues) =>
    await axiosInstance.post('/api/talent/skill_lang_hobby', apiValues, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "multipart/form-data",
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })