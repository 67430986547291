import moment from "moment-timezone";
import 'moment/locale/fr'

export function capitalizeFirstLetter(string) {
    if (!string || typeof string === 'object') {
        return "";
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function displayDateRange(start, end, isCurrent = false, todayText = '') {
    let startDate = "";
    let endDate = "";
    let displayRange = "";

    if (start) {
        startDate = moment(start, "YYYY-MM-DD HH:mm:ss")
    }

    if (end) {
        endDate = moment(end, "YYYY-MM-DD HH:mm:ss")
    }

    if (start && end) {
        displayRange = capitalizeFirstLetter(startDate.locale('fr').format('MMM YYYY')) + " - " + capitalizeFirstLetter(endDate.locale('fr').format('MMM YYYY'))
    }

    if (start && !end) {
        displayRange = capitalizeFirstLetter(startDate.locale('fr').format('MMM YYYY'))

        if (isCurrent) {
            displayRange += " - " + capitalizeFirstLetter(todayText)
        }
    }

    return displayRange;
}

export function reformatCountry(countries, countryCode) {
    let option = ''

    for (let i = 0; i < countries.length; i++) {
        if (countries[i].code === countryCode) {
            option = countries[i]
            break
        }
    }

    return option
}

export function reformatRefId(refs, value) {
    let option = ''

    for (let i = 0; i < refs.length; i++) {
        if (refs[i].id === value) {
            option = refs[i]
            break
        }
    }

    return option
}

export function getSelectedLabels(selected, list, field = 'id') {
    let arrayNames = list?.map((listItem) => {
        return selected?.indexOf(field ? listItem?.[field] : listItem) > -1 ? (field ? listItem?.name : listItem) : ''
    })?.filter((item) => item)

    return arrayNames.join(', ')
}

export function getSelectedLabelsWorkLocation(selected, list, field = 'id', key = 'name') {
    let arrayNames = list?.map((listItem) => {
        return selected?.indexOf(field ? listItem?.[field] : listItem) > -1 ? (field ? listItem[key] : listItem) : ''
    })?.filter((item) => item)

    return arrayNames.join(', ')
}

export function dateStringToMoment(dateString, splitter = ' ') {

    if (dateString && (typeof dateString === 'string' || dateString instanceof String) && moment(dateString).isValid()) {
        return moment(dateString?.split(splitter)[0], 'YYYY-MM-DD')
    }

    return null
}

export function formatNumber(number, separator) {
    if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }

    return number;
}

export function formatGraphQL(data) {
    if (data) {
        return data.replace(/"/g, '\\"');
    }

    return data;
}

export function getEscapedUnicode(originalString) {
    const unicodeEscapedString = originalString.replace(/[\u0080-\uFFFF]/g, function(match) {
        return "\\u" + match.charCodeAt(0).toString(16).padStart(4, "0");
    });

    return unicodeEscapedString;
}

export function formatTimeForTimePicker(time) {
    const today = new Date();
    const timeArray = time.split(':');
    today.setHours(timeArray[0]);
    today.setMinutes(timeArray[1]);
    today.setSeconds(0);
    today.setMilliseconds(0);

    return today;
}