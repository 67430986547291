// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {GET_CHATGPT, CHECK_JOB} from '../action-types'

// Import all api's
import {getChatgptRequest, checkAutoJobRequest} from '../api/Chatgpt'

import {getChatgptSuccess, checkJobSuccess} from '../actions/Chatgpt'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'

function* getChatgpt({token, chat_type, params}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getChatgptRequest, token, chat_type, params)
        if (response.status === 200) {
            yield put(getChatgptSuccess(response?.data, chat_type))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* checkGpt({token, id}) {
    try {
        //yield put(fetchStart())
        const response = yield call(checkAutoJobRequest, token, id)
        if (response.status === 200) {
            yield put(checkJobSuccess(response?.data))
            yield put(fetchSuccess())
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_CHATGPT, getChatgpt)
    yield takeEvery(CHECK_JOB, checkGpt)
}