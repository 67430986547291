import React, {useEffect, useState} from 'react'
import {injectIntl} from "react-intl"
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch} from "react-redux";
import {changeLocale} from "../../redux/actions/Common";
import {getLocale} from "../../utils/compute";

const Login = ({intl}) => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    let {lang, type} = useParams()

    console.log(lang, type)
    useEffect(() => {
        dispatch(changeLocale(getLocale(lang)))
        navigate('/register/' + type)
    }, [])

    return (
        <div className="login-container">

        </div>
    )
}

export default injectIntl(Login)
