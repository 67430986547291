import {axiosInstance, axiosInstanceGraphQL} from '../../axios/axios'

export const getJobOffersRequest = async (token, query) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: query
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const deleteJobOfferRequest = async (token, id) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `mutation {
          deleteJobOffer(id: ${id}) {
            id
          }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateApplicationTimeRequest = async (token, id, status) =>
    await axiosInstance.put('/api/job_offer/update_application_time', {
        id: id,
        status: status
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const addApplicationTimeRequest = async (token, data) => {
    return await axiosInstance.post('/api/job_offer/add_application_time', data, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const updateJobOfferRequest = async (token, id,data) => {
    return await axiosInstance.put('/api/job_offer/update_template/'+id, data, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const jobOfferFicheRequest = async (token, data) => {
    return await axiosInstance.post('/api/job_offer/fiche', data, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const jobOfferProposalRequest = async (token) => {
    return await axiosInstance.get('/api/job_offer/proposals', {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const jobOfferContractRequest = async (token, id) => {
    return await axiosInstance.get('/api/job_offer/contract_template/' + id, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const signJobOfferContractRequest = async (token, data) => {
    return await axiosInstance.post('/api/job_offer/contract_sign', data,{
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const updateContractRequest = async (token, data) => {
    return await axiosInstance.post('/api/job_offer/contract_update', data,{
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const endContractRequest = async (token, data) => {
    return await axiosInstance.post('/api/job_offer/contract_end', data,{
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}
