import {
    CLOSE_FILTER_JOB,
    DELETE_SEARCH,
    DELETE_SEARCH_SUCCESS,
    GET_SEARCH,
    GET_SEARCH_SUCCESS, OPEN_FILTER_JOB,
    SAVE_SEARCH,
    SAVE_SEARCH_SUCCESS,
    SEARCH_ACTION,
    SEARCH_ACTION_SUCCESS,
    UPLOAD_CV_WARNING,
    TALENT_ALREADY_APPLIED,
    SET_SEARCH_STATE,
    SEARCH_MATCH_SUCCESS, SEARCH_MATCH
} from '../action-types'

export const saveSearch = (token, form) => {
    return {
        type: SAVE_SEARCH,
        token: token,
        form: form
    }
}

export const saveSearchSuccess = (data) => {
    return {
        type: SAVE_SEARCH_SUCCESS,
        data: data,
    }
}

export const getSearch = (token) => {
    return {
        type: GET_SEARCH,
        token: token
    }
}

export const getSearchSuccess = (data) => {
    return {
        type: GET_SEARCH_SUCCESS,
        data: data,
    }
}

export const deleteSearch = (token, id) => {
    return {
        type: DELETE_SEARCH,
        token: token,
        id: id
    }
}

export const deleteSearchSuccess = (data) => {
    return {
        type: DELETE_SEARCH_SUCCESS,
        data: data,
    }
}

export const searchAction = (token, form) => {
    return {
        type: SEARCH_ACTION,
        token: token,
        form: form
    }
}

export const searchActionSuccess = (data) => {
    return {
        type: SEARCH_ACTION_SUCCESS,
        data: data,
    }
}

export const toggleShowCVWarning = () => {
    return {
        type: UPLOAD_CV_WARNING
    }
}

export const talentALreadyApplied = (data) => {
    return {
        type: TALENT_ALREADY_APPLIED,
        data: data,
    }
}

export const openFilterJob = () => {
    return {
        type: OPEN_FILTER_JOB,
    }
}

export const closeFilterJob = () => {
    return {
        type: CLOSE_FILTER_JOB,
    }
}

export const setTalentState = (state) => {
    return {
        type: SET_SEARCH_STATE,
        payload: state,
    }
}

export const searchMatch = (token, id) => {
    return {
        type: SEARCH_MATCH,
        token: token,
        id: id
    }
}

export const searchMatchSuccess = (data) => {
    return {
        type: SEARCH_MATCH_SUCCESS,
        data: data,
    }
}
