// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {CREATE_REF, RESET_REF, CREATE_AUTO, TRACK_STATE} from '../action-types'

// Import all api's
import {autoRefRequest, createRefRequest, trackRequest} from '../api/Ref'

import {createRefSuccess, resetRefSuccess, autoRefSuccess} from '../actions/Ref'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'

function* createRef({token, apiValues, localValues, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(createRefRequest, token, apiValues, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(createRefSuccess(response?.data, localValues))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* autoRef({token, locale, form }) {
    try {
        //yield put(fetchStart())
        const response = yield call(autoRefRequest, token, locale, form)
        if (response.status === 200) {
            yield put(autoRefSuccess(response?.data))
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* track({token, action }) {
    try {
        const response = yield call(trackRequest, token, action)
        if (response.status === 200) {

        }
    } catch (error) {
        console.log(error)
    }
}

function* resetRef() {
    yield put(resetRefSuccess())
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(CREATE_REF, createRef)
    yield takeEvery(RESET_REF, resetRef)
    yield takeEvery(CREATE_AUTO, autoRef)
    yield takeEvery(TRACK_STATE, track)
}