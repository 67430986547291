import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
    getAvailability,
    getContractLevel,
    getContractMode,
    getContractTime,
    getContractType,
    getCountries,
    getCurrency,
    getEducationLevel,
    getEducationStatus,
    getExpRange,
    getGender,
    getSector,
    getIndustry,
    getSubIndustry,
    getSubSubIndustry,
    getLanguage,
    getSearchUrgency, getTimezone, getValue,
    getWorkLocation, getModules,
} from '../../redux/actions/List'
import {setCommonState} from '../../redux/actions/Common'


const PageContainer = ({children}) => {
    const dispatch = useDispatch()

    const {loading, scrollToTop, locale} = useSelector(({common}) => common)
    const {token} = useSelector(({auth}) => auth)
    const {
        countries,
        education_status,
        education_level,
        genders,
        language,
        search_urgency,
        availability,
        contract_mode,
        contract_type,
        contract_time,
        contract_level,
        work_location,
        sector,
        industry,
        sub_industry,
        sub_sub_industry,
        exp_range,
        currency,
        timezones,
        values,
        modules,
    } = useSelector(({list}) => list)

    const [fullLoading, setFullLoading] = useState(false)

    useEffect(() => {
        if (scrollToTop) {
            document.getElementsByClassName('App')?.[0]?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
            dispatch(setCommonState({scrollToTop: false}))
        }
    }, [scrollToTop])

    useEffect(() => {
        if (token && (!exp_range || exp_range?.length === 0)) {
            dispatch(getExpRange(token, {}, locale))
        }
    }, [exp_range, locale])

    useEffect(() => {
        if (token && (!sector || sector?.length === 0)) {
            dispatch(getSector(token, {}, locale))
        }
    }, [sector, locale])

    useEffect(() => {
        if (token && (!industry || industry?.length === 0)) {
            dispatch(getIndustry(token, {}, locale))
        }
    }, [industry, locale])

    useEffect(() => {
        if (token && (!sub_industry || sub_industry?.length === 0)) {
            dispatch(getSubIndustry(token, {}, locale))
        }
    }, [sub_industry, locale])

    useEffect(() => {
        if (token && (!sub_sub_industry || sub_sub_industry?.length === 0)) {
            dispatch(getSubSubIndustry(token, {}, locale))
        }
    }, [sub_sub_industry, locale])

    useEffect(() => {
        if (token && (!work_location || work_location?.length === 0)) {
            dispatch(getWorkLocation(token, {}, locale))
        }
    }, [work_location, locale])

    useEffect(() => {
        if (token && (!contract_level || contract_level?.length === 0)) {
            dispatch(getContractLevel(token, {}, locale))
        }
    }, [contract_level, locale])

    useEffect(() => {
        if (token && (!contract_time || contract_time?.length === 0)) {
            dispatch(getContractTime(token, {}, locale))
        }
    }, [contract_time, locale])

    useEffect(() => {
        if (token && (!contract_type || contract_type?.length === 0)) {
            dispatch(getContractType(token, {}, locale))
        }
    }, [contract_type, locale])

    useEffect(() => {
        if (token && (!contract_mode || contract_mode?.length === 0)) {
            dispatch(getContractMode(token, {}, locale))
        }
    }, [contract_mode, locale])

    useEffect(() => {
        if (!countries || countries?.length === 0) {
            dispatch(getCountries(token, {}, locale))
        }
    }, [countries, locale])

    useEffect(() => {
        if (token && (!education_status || education_status?.length === 0)) {
            dispatch(getEducationStatus(token, {}, locale))
        }
    }, [education_status, locale])

    useEffect(() => {
        if (token && (!education_level || education_level?.length === 0)) {
            dispatch(getEducationLevel(token, {}, locale))
        }
    }, [education_level, locale])

    useEffect(() => {
        if (token && (!genders || genders?.length === 0)) {
            dispatch(getGender(token, {}, locale))
        }
    }, [genders, locale])

    useEffect(() => {
        if (!language || language?.length === 0) {
            dispatch(getLanguage(token, {}, locale))
        }
    }, [language, locale])

    useEffect(() => {
        if (token && (!search_urgency || search_urgency?.length === 0)) {
            dispatch(getSearchUrgency(token, {}, locale))
        }
    }, [search_urgency, locale])

    useEffect(() => {
        if (token && (!availability || availability?.length === 0)) {
            dispatch(getAvailability(token, {}, locale))
        }
    }, [availability, locale])

    useEffect(() => {
        if (token && (!currency || currency?.length === 0)) {
            dispatch(getCurrency(token, {}, locale))
        }
    }, [currency, locale])

    useEffect(() => {
        if (token && (!timezones || timezones?.length === 0)) {
            dispatch(getTimezone(token, {}, locale))
        }
    }, [timezones, locale])

    useEffect(() => {
        if (token && (!values || values?.length === 0)) {
            dispatch(getValue(token, {}, locale))
        }
    }, [values, locale])

    useEffect(() => {
        if (token && (!modules || modules?.length === 0)) {
            dispatch(getModules(token, {}, locale))
        }
    }, [modules, locale])


    useEffect(() => {
        if (
            exp_range?.length !== 0 &&
            sector?.length !== 0 &&
            industry?.length !== 0 &&
            work_location?.length !== 0 &&
            contract_level?.length !== 0 &&
            contract_time?.length !== 0 &&
            contract_type?.length !== 0 &&
            contract_mode?.length !== 0 &&
            countries?.length !== 0 &&
            education_status?.length !== 0 &&
            education_level?.length !== 0 &&
            genders?.length !== 0 &&
            language?.length !== 0 &&
            search_urgency?.length !== 0 &&
            availability?.length !== 0 &&
            currency?.length !== 0 &&
            timezones?.length !== 0 &&
            modules?.length !== 0
        ) {
            setFullLoading(false)
        }
    }, [
        exp_range,industry,work_location,contract_level,contract_time,contract_type,contract_mode, sector,
        countries,education_status, education_level,genders,language,search_urgency,availability,currency,modules
    ])

    return (
        <div>
            {children}

            <Backdrop
                sx={{color: '#0284c7', backgroundColor: 'white', opacity: '0.5 !important' , zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
                invisible
            >
                <CircularProgress size={100} color="inherit"/>
            </Backdrop>
        </div>
    )
}

export default PageContainer
