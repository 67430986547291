import {
    GET_CHATGPT_SUCCESS,
    CLEAR_JOB_ID_CHATGPT, SET_GPT_STATE, CHECK_JOB_SUCCESS
} from '../action-types'

const INIT_STATE = {
    job_offer_id: null,
    profile_uploaded: false,
    loading_job: false,
    recheck_job: 0,
    loading_job_done: false,
    loading_job_error: false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CHATGPT_SUCCESS: {
            if (action?.chat_type === 'job_profile') {
                return {...state, job_offer_id: action?.data?.job_offer_id, loading_job: true, recheck_job: 1}
            }
            if (action?.chat_type === 'talent_profile') {
                return {...state, profile_uploaded: true}
            }
        }

        case CLEAR_JOB_ID_CHATGPT: {
            return {...state, job_offer_id: null, loading_job: false, recheck_job: 0, loading_job_done: false, loading_job_error: false}
        }

        case SET_GPT_STATE: {
            let stateCopy = {...state}
            stateCopy[action?.payload?.key] = action?.payload?.value

            return stateCopy
        }

        case CHECK_JOB_SUCCESS: {
            if (action.data.status == 'generating') {
                return {...state, recheck_job: state.recheck_job + 1}
            }
            if (action.data.status == 'generated') {
                return {...state, loading_job_done: true, loading_job: false, loading_job_error: false, recheck_job: 0}
            }
            if (action.data.status == 'generate_failed') {
                return {...state, loading_job_done: false, loading_job: false, loading_job_error: true, recheck_job: 0}
            }
        }

        default:
            return state
    }
}
