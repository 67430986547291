import React, {useEffect, useState} from 'react'
import {injectIntl} from "react-intl"
import {refuseInvitation} from "../../redux/api/Auth";
import PageTitle from "../../components/common/PageTitle";

const InvitationRefused = ({intl}) => {

    const [uid, setUid] = useState(null)
    const [refused, setRefused] = useState(false)
    const [error, setError] = useState(null)
    useEffect(() => {
        if (uid === null) {
            const urlParams = new URLSearchParams(window.location.search);
            const urlUid = urlParams.get('uid')
            setUid(urlUid)
        }
    }, [])

    useEffect(() => {
        refuseInvitation(uid).then((response) => {
            if (response.status === 200) {
                setRefused(true)
            } else {
                setError(response.data.message)
            }
        })
    }, [uid])

    return (
        <div className="login-container">
            <header className="py-2 flex flex-col space-y-5 justify-center items-center">
                <img src={require('./../../assets/images/logo.png')} className="w-96" alt="Logo de Talenteum"/>
            </header>

            <main className="my-8 flex justify-center">
                <PageTitle title={"you.refuse.invit"} />
            </main>
        </div>
    )
}

export default injectIntl(InvitationRefused)
