import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {injectIntl} from "react-intl"
import TextField from '@mui/material/TextField'
import {debounce} from '@mui/material/utils'
import {
    getCertification,
    getCities,
    getEducation,
    getHobby,
    getRefJobTitle,
    getRefUniversity,
    getSkill
} from '../../../redux/actions/List'
import {createRef} from '../../../redux/actions/Ref'
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {capitalizeFirstLetter} from "../../../utils/format";

const AutocompleteCreatable = ({intl, label, tableName, fieldName, value, onValueChange, error, helperText, disableClearable, showFullResult}) => {
    const _ = require('lodash')
    const dispatch = useDispatch()
    const filter = createFilterOptions()

    const {token} = useSelector(({auth}) => auth)
    const {cities, job_title, university, education, certification, skill, hobbies} = useSelector(({list}) => list)

    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([])
    const {locale} = useSelector(({common}) => common)

    const fetch = useMemo(
        () =>
            debounce(
                (
                    request,
                    callback,
                ) => {
                    request = request.toLocaleLowerCase()
                    switch (tableName) {
                        case 'ref_cities':
                            dispatch(getCities(token, `%${request}%`), locale)
                            break

                        case 'ref_job_title':
                            dispatch(getRefJobTitle(token, `%${request}%`), locale)
                            break

                        case 'ref_universities':
                            dispatch(getRefUniversity(token, `%${request}%`), locale)
                            break

                        case 'ref_educations':
                            dispatch(getEducation(token, `%${request}%`), locale)
                            break

                        case 'ref_certification':
                            dispatch(getCertification(token, `%${request}%`), locale)
                            break

                        case 'ref_skills':
                            dispatch(getSkill(token, `%${request}%`), locale)
                            break

                        case 'ref_hobbies':
                            dispatch(getHobby(token, `%${request}%`), locale)
                            break
                    }
                },
                500,
            ),
        [],
    );

    useEffect(() => {
        if (inputValue === '') {
            setOptions(value ? [value] : [])
            return undefined
        }

        fetch(inputValue, () => {
        })
    }, [value, inputValue])

    useEffect(() => {
        let newOptions = []
        let results = []
        let newInputValue = inputValue.toLocaleLowerCase()
        let newValueName = value?.name ? value.name.toLocaleLowerCase() : null

        if (value) {
            newOptions = [value]
        }

        let newOptionsList = []

        switch (tableName) {
            case 'ref_cities':
                newOptionsList = [...cities]
                break

            case 'ref_job_title':
                newOptionsList = [...job_title]
                break

            case 'ref_universities':
                newOptionsList = [...university]
                break

            case 'ref_educations':
                newOptionsList = [...education]
                break

            case 'ref_certification':
                newOptionsList = [...certification]
                break

            case 'ref_skills':
                newOptionsList = [...skill]
                break

            case 'ref_hobbies':
                newOptionsList = [...hobbies]
                break
        }

        if (newOptionsList?.length > 0) {
            results = newOptionsList.filter((option) => {
                const optionName = option?.name?.toLocaleLowerCase()

                if (showFullResult && showFullResult === true) {
                    return optionName !== newValueName
                }

                return optionName?.startsWith(newInputValue) && (optionName !== newValueName)
            })
        }

        if (results) {
            newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
    }, [cities, job_title, university, education, certification, skill, hobbies, value])

    const addNewValue = (value) => {
        setInputValue("")

        dispatch(createRef(
            token,
            {
                name: value,
                table: tableName
            },
            {fieldName, value},
            locale
        ))
    }

    return (
        <Autocomplete
            disableClearable={disableClearable}
            fullWidth
            value={value || ''}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const {inputValue} = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        name: `${intl.formatMessage({id: 'add'})} "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={options}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return capitalizeFirstLetter(option?.name);
            }}
            renderOption={(props, option) => {
                if (option?.name.includes("outer")) {
                    return (<li {...props} className={'text-green-600 cursor-pointer ml-4'}>{capitalizeFirstLetter(option?.name)}</li>)
                } else {
                    return (<li {...props}>{capitalizeFirstLetter(option?.name)}</li>)
                }
            }}
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={error}
                    helperText={helperText}
                />
            )}
            autoComplete
            includeInputInList
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    onValueChange({
                        name: newValue,
                    })
                } else if (newValue && newValue.inputValue) {
                    addNewValue(newValue.inputValue)
                } else {
                    onValueChange(newValue)
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            onBlur={() => {
                if (inputValue) {
                    addNewValue(inputValue)
                }
            }}
            onKeyPress={(event) => {
                if (event?.key === 'Enter' && inputValue) {
                    addNewValue(inputValue)
                }
            }}
        />
    )
}

export default injectIntl(AutocompleteCreatable)
