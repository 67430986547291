import React from 'react'
import PersonalData from '../PersonalData'
import Avatar from '../Avatar'

const ProfilPersonalData = () => {
  return (
    <div className='flex flex-col gap-6'>
        <PersonalData />
        <Avatar/>
    </div>
  )
}

export default ProfilPersonalData