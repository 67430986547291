import React from "react";

export const routeList = [
    {
        path: "/talent/personal-data",
        icon: "fa-solid fa-person ",
        label: "personal.data"
    },
    {
        path: "/talent/documents",
        icon: "fa-solid fa-file ",
        label: "my.documents"
    },
    {
        path: "/talent/preference",
        icon: "fa-solid fa-thumbs-up ",
        label: "preferences"
    },
    {
        path: "/talent/avatar",
        icon: "fa-solid fa-thumbs-up ",
        label: "Avatar"
    },
    {
        path: "/talent/mdp",
        icon: "fa-solid fa-lock ",
        label: "password"
    },
];

export const renderDoneCheck = (nav, talent, user) => {
    let done = false;

    switch (nav) {
        case "/talent/personal-data":
            if (
                talent?.gender_id && talent?.date_of_birth && talent?.nationality_code && talent?.address_country_code &&
                talent?.address_street_1 && talent?.address_street_2 && talent?.address_zip_code && talent?.address_city_id
            ) {
                done = true
            }
            break;
        case "/talent/education":
            if (
                talent?.completed_education
            ) {
                done = true
            }
            break;
        case "/talent/experience":
            if (
                talent?.completed_experience
            ) {
                done = true
            }
            break;
        case "/talent/certification":
            if (
                talent?.completed_certification || talent?.has_no_certification
            ) {
                done = true
            }
            break;
        case "/talent/languages":
            if (
                talent?.languages?.length > 0 && talent?.skills?.length
            ) {
                done = true
            }
            break;
        case "/talent/hobby":
            if (
                talent?.hobbies?.length > 0
            ) {
                done = true
            }
            break;
        case "/talent/documents":
            if (
                talent?.document_address && talent?.document_id_card
            ) {
                done = true
            }
            break;
        case "/talent/preference":
            if (
                talent?.availability_id && talent?.search_urgency_id &&
                talent?.preferred_language_code && talent?.seeking_job_title_id &&
                talent?.contract_types?.length > 0 && talent?.work_location?.length > 0
            ) {
                done = true
            }
            break;
        case "/talent/presentation":
            if (
                talent?.presentation_video
            ) {
                done = true
            }
            break;
        case "/talent/social":
            if (
                talent?.whatsapp
            ) {
                done = true
            }
            break;
        case "/talent/avatar":
            if (
                talent?.avatar_id
            ) {
                done = true
            }
            break;
        case "/talent/salary":
            let contractTypes = [];
            for (let i = 0; i <talent?.contract_types.length; i++) {
                contractTypes.push(talent?.contract_types[i].id)
            }
            if (
                (talent?.contract_types.length === 1 && contractTypes.includes("1") && parseInt(talent?.employed_salary) > 0) ||
                (talent?.contract_types.length === 1 && contractTypes.includes("2") && parseInt(talent?.contractor_salary) > 0 && talent?.contractor_salary_currency_code) ||
                (talent?.contract_types.length === 2 && talent?.employed_salary && parseInt(talent?.employed_salary) && parseInt(talent?.contractor_salary) && talent?.contractor_salary_currency_code)
            ) {
                done = true
            }
            break;
        case "/talent/mdp":
            if (
                user && !user?.should_reset
            ) {
                done = true
            }
            break;
        case "/talent/preview":
            done = true
            break;

    }

    if (done) {
        return (
            <i className={'fa fa-check text-green-600 ml-2 mr-2'}></i>
        )
    } else {
        return (
            <i className={'fa-regular fa-circle ml-2 mr-2'}></i>
        )
    }
}

export const renderTalentPageTitle = (nav) => {
    switch (nav) {
        case "/talent/personal-data":
            return "personal.data"
        case "/talent/education":
            return "education"
        case "/talent/experience":
            return "experience"
        case "/talent/certification":
            return "certifications"
        case "/talent/languages":
            return "languages.skills"
        case "/talent/hobby":
            return "hobbies"
        case "/talent/documents":
            return "my.documents"
        case "/talent/preference":
            return "preferences"
        case "/talent/presentation":
            return "salary"
        case "/talent/social":
            return "my.presentation"
        case "/talent/avatar":
            return "my.avatar"
        case "/talent/salary":
            return "salary"
        case "/talent/mdp":
            return "password"
        case "/talent/preview":
            return "preview"
        case "/talent/search/job_offers":
            return "job_offer"
        default:
            return "page"

    }
}
