import axios from "axios";

export const api_url = process.env.REACT_APP_API_URL;
export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 240000,
});

axiosInstance.interceptors.request.use(function (config) {
    // config.headers = { ...config.headers, auth_token: getAuthToken() };
    // you can also do other modification in config
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    if (response.status === 401) {
        // your failure logic
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

export const axiosInstanceGraphQL = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 100000,
});

axiosInstanceGraphQL.interceptors.request.use(function (config) {
    // config.headers = { ...config.headers, auth_token: getAuthToken() };
    // you can also do other modification in config
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosInstanceGraphQL.interceptors.response.use(function (response) {
    if (response.status === 401) {
        // your failure logic
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

const getAuthToken = () => {
    const token = localStorage.getItem('breedj_user_token');
    return token ? JSON.parse(token) : null;
};
  
export const axiosInstanceES = axios.create({
    baseURL: process.env.REACT_APP_ES_URL,
    timeout: 100000,
});

axiosInstanceES.interceptors.request.use(
    function (config) {
        const token = getAuthToken();
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstanceES.interceptors.response.use(function (response) {
    if (response.status === 401) {
        // your failure logic
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});
