import {
    GET_CHATGPT,
    GET_CHATGPT_SUCCESS,
    CLEAR_JOB_ID_CHATGPT,
    SET_GPT_STATE,
    CHECK_JOB,
    CHECK_JOB_SUCCESS,
} from '../action-types'

export const getChatgpt = (token, chat_type, params) => {
    return {
        type: GET_CHATGPT,
        token: token,
        chat_type: chat_type,
        params: params
    }
}

export const getChatgptSuccess = (data, chat_type) => {
    return {
        type: GET_CHATGPT_SUCCESS,
        data: data,
        chat_type: chat_type,
    }
}

export const checkJob = (token, id) => {
    return {
        type: CHECK_JOB,
        token: token,
        id: id
    }
}

export const checkJobSuccess = (data) => {
    return {
        type: CHECK_JOB_SUCCESS,
        data: data,
    }
}

export const clearJobId = () => {
    return {
        type: CLEAR_JOB_ID_CHATGPT
    }
}

export const setGptState = (field, value) => {
    return {
        type: SET_GPT_STATE,
        payload: {
            key : field,
            value: value
        }
    }
}