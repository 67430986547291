import React, {useEffect, useState} from 'react'
import {injectIntl} from "react-intl"
import {useDispatch, useSelector} from "react-redux"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField";
import {Link, useNavigate, useParams} from 'react-router-dom'
import {registerUser, userSignInSuccess} from "../../redux/actions/Auth"
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import {useFormik} from 'formik'
import * as yup from 'yup'
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {changeLocale} from "../../redux/actions/Common";
import {getLocale} from "../../utils/compute";
import MenuItem from "@mui/material/MenuItem";
import {capitalizeFirstLetter} from "../../utils/format";

const Register = ({intl}) => {
    const dispatch = useDispatch()
    let navigate = useNavigate()


    const params = new URLSearchParams(window.location.search)
    const first_name = params.get('first_name')
    const last_name = params.get('last_name')
    const token = params.get('token')
    const exists = params.get('exists')
    const role = params.get('role')

    dispatch(userSignInSuccess({
        token : token,
        user: {
            first_name: first_name,
            last_name: last_name
        }
    }))

    React.useEffect(() => {
        if (exists === '0') {
            navigate('/oauth/linkedin?first_name=' + first_name + '&last_name=' + last_name)
        }
        if (exists === '1' && role === 'talent') {
            window.location.replace('/talent/dashboard')
        }
        if (exists === '1' && role === 'company') {
            window.location.replace('/company/dashboard')
        }

    }, [dispatch])



    return (
        <div className="login-container">

        </div>
    )
}

export default injectIntl(Register)
//http://localhost:3000/registeroauth?first_name=mushood&last_name=talenteum&token=5237%7CATb3vIpGWxZT7Dm2KyynBHgWoe9RuCb613xCet90508a6d07