import {
    FETCH_ERROR,
    FETCH_ERROR_CUSTOM,
    FETCH_EXPIRED,
    FETCH_START,
    FETCH_START_WITHOUT_LOADING,
    FETCH_SUCCESS,
    HIDE_MESSAGE,
    HIDE_MESSAGE_UNAUTH,
    POST_SUCCESS,
    REQUEST_SUCCESS,
    SET_COMMON_STATE,
    SHOW_MESSAGE,
    SHOW_UNAUTH,
    CHANGE_LOCALE
} from '../action-types'

export const fetchStart = () => {
    return {
        type: FETCH_START
    }
}

export const fetchStartWithoutLoading = () => {
    return {
        type: FETCH_START_WITHOUT_LOADING
    }
}

export const fetchExpired = () => {
    return {
        type: FETCH_EXPIRED
    }
}

export const fetchSuccess = () => {
    return {
        type: FETCH_SUCCESS
    }
}

export const postSuccess = () => {
    return {
        type: POST_SUCCESS
    }
}

export const requestSuccess = (data) => {
    return {
        type: REQUEST_SUCCESS,
        data
    }
}

export const fetchError = (error) => {
    return {
        type: FETCH_ERROR,
        payload: error
    }
}

export const fetchErrorCustom = (message) => {
    return {
        type: FETCH_ERROR_CUSTOM,
        message: message
    }
}

export const showMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    }
}

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE
    }
}

export const showUnauth = () => {
    return {
        type: SHOW_UNAUTH
    }
}


export const hideMessageUnauth = () => {
    return {
        type: HIDE_MESSAGE_UNAUTH
    }
}

export const changeLocale = (locale) => {
    return {
        type: CHANGE_LOCALE,
        locale: locale
    }
}

export const setCommonState = (state) => {
    return {
        type: SET_COMMON_STATE,
        payload: state,
    }
}
