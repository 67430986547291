import {
    GET_CURRENT_USER,
    GET_CURRENT_USER_SUCCESS,
    REGISTER_RESEND_EMAIL,
    REGISTER_RESEND_EMAIL_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    RESET_PWD,
    RESET_PWD_SUCCESS,
    SEND_OTP,
    SEND_OTP_SUCCESS,
    SET_AUTH_STATE,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    VALIDATE_OTP,
    VALIDATE_OTP_SUCCESS,
    REGISTER_USER_LINKEDIN,
    REGISTER_USER_LINKEDIN_SUCCESS
} from '../action-types'

export const getCurrentUser = (token) => {
    return {
        type: GET_CURRENT_USER,
        token: token
    }
}

export const getCurrentUserSuccess = (data) => {
    return {
        type: GET_CURRENT_USER_SUCCESS,
        payload: data,
    }
}

export const userSignIn = (form, intl) => {
    return {
        type: SIGNIN_USER,
        payload: form,
        intl: intl
    }
}

export const userSignInSuccess = (token) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: token,
    }
}

export const userSignOut = (token) => {
    return {
        type: SIGNOUT_USER,
        token: token
    }
}

export const userSignOutSuccess = (token) => {
    return {
        type: SIGNOUT_USER_SUCCESS,
        payload: token,
    }
}

export const sendOtp = (token, form) => {
    return {
        type: SEND_OTP,
        token: token,
        form: form
    }
}

export const sendOtpSuccess = () => {
    return {
        type: SEND_OTP_SUCCESS
    }
}

export const validateOtp = (token, form) => {
    return {
        type: VALIDATE_OTP,
        token: token,
        form: form
    }
}

export const validateOtpSuccess = (data) => {
    return {
        type: VALIDATE_OTP_SUCCESS,
        payload: data,
    }
}

export const resetPwd = (token, form, intl) => {
    return {
        type: RESET_PWD,
        token: token,
        form: form,
        intl: intl
    }
}

export const resetPwdSuccess = (data) => {
    return {
        type: RESET_PWD_SUCCESS,
        payload: data,
    }
}

export const registerUser = (form) => {
    return {
        type: REGISTER_USER,
        form: form
    }
}

export const registerUserSuccess = (data) => {
    return {
        type: REGISTER_USER_SUCCESS,
        payload: data,
    }
}

export const registerUserLinkedin = (token, form) => {
    return {
        type: REGISTER_USER_LINKEDIN,
        token: token,
        form: form
    }
}

export const registerUserLinkedinSuccess = (data) => {
    return {
        type: REGISTER_USER_LINKEDIN_SUCCESS,
        payload: data,
    }
}

export const registerResendEmail = (form, intl) => {
    return {
        type: REGISTER_RESEND_EMAIL,
        form: form,
        intl: intl
    }
}

export const registerResendEmailSuccess = (data) => {
    return {
        type: REGISTER_RESEND_EMAIL_SUCCESS,
        payload: data,
    }
}

export const setAuthState = (state) => {
    return {
        type: SET_AUTH_STATE,
        payload: state,
    }
}

export const autoLogin = (uid) => {
    return {
        type: 'AUTO_LOGIN',
        uid: uid
    }
}









