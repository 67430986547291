import {axiosInstance, axiosInstanceGraphQL} from '../../axios/axios'

export const getContactRequest = async (token, query) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: query
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateContactRequest = async (token, query) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: query
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateContactPhoneRequest = async (token,values) =>
    await axiosInstance.put('/api/auth/user',values, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then(function (response) {
                    return response
                })
                .catch(function (error) {
                    return error.response
                })


export const deleteContactRequest = async (token, query) =>
    await axiosInstanceGraphQL .post( "/graphql", {
        query: query,
    }, {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });


export const sendInvitationRequest = async (token, id) =>
    await axiosInstanceGraphQL .post( "/api/contact/send_invitation", {
        id: id,
    }, {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });


export const updateCompanyInvoiceRequest = async (token, data) =>
    await axiosInstanceGraphQL.post( "/api/company/invoice", data, {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });


export const updateCompanyInvoiceSingleRequest = async (token, id) =>
    await axiosInstanceGraphQL.get( "/api/company/invoice/" + id, {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });


export const updateCompanyInvoiceTicketRequest = async (token, form) =>
    await axiosInstanceGraphQL.post( "/api/company/invoice_ticket", form, {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });


export const updateCompanyInvoiceValidateRequest = async (token, id) =>
    await axiosInstanceGraphQL.get( "/api/company/invoice_validate/" + id, {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });


export const updateCompanyInvoiceValidateItemRequest = async (token, form) =>
    await axiosInstanceGraphQL.post( "/api/company/invoice/item/validate", form, {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });