import {
    GET_COMPANY,
    GET_COMPANY_SUCCESS,
    GET_CONTACT,
    GET_CONTACT_SUCCESS,
    UPDATE_COMPANY,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_CONTACT,
    UPDATE_CONTACT_SUCCESS,
    REFETCH_CONTACT,
    DELETE_CONTACT,
    DELETE_CONTACT_SUCCESS,
    SEND_INVITATION,
    SEND_INVITATION_SUCCESS,
    GET_COMPANY_GROUPS,
    GET_COMPANY_GROUPS_SUCCESS,
    GET_MODULE_GROUPS,
    GET_MODULE_GROUPS_SUCCESS,
    SET_CONTACT_STATE,
    SET_COMPANY_STATE,
    SET_COMPANY_VALUE_STATE,
    SET_COMPANY_VALUE_ORDER,
    GET_COMPANY_TEAM,
    GET_COMPANY_TEAM_SUCCESS,
    GET_COMPANY_INVOICE_SUCCESS,
    GET_COMPANY_INVOICE,
    UPDATE_COMPANY_INVOICE_SUCCESS,
    UPDATE_COMPANY_INVOICE,
    GET_COMPANY_INVOICE_SINGLE,
    GET_COMPANY_INVOICE_SINGLE_SUCCESS,
    GET_COMPANY_INVOICE_TICKET_SUCCESS,
    GET_COMPANY_INVOICE_TICKET,
    GET_COMPANY_INVOICE_VALIDATE_SUCCESS,
    GET_COMPANY_INVOICE_VALIDATE,
    GET_COMPANY_INVOICE_VALIDATE_IEM_SUCCESS,
    GET_COMPANY_INVOICE_VALIDATE_ITEM,
    GET_COMPANY_INVOICE_VALIDATE_ITEM_SUCCESS
} from '../action-types'

export const getContact = (token, query) => {
    return {
        type: GET_CONTACT,
        token: token,
        query: query
    }
}

export const getContactSuccess = (data) => {
    return {
        type: GET_CONTACT_SUCCESS,
        data: data?.data,
    }
}

export const updateContact = (token, query, typeUpdate, intl, loading) => {
  return {
    type: UPDATE_CONTACT,
    token: token,
    query: query,
    typeUpdate: typeUpdate,
    intl: intl,
    loading: loading,
  };
};

export const updateContactPhone = (token, values, loading, intl) => {
    return {
        type: UPDATE_CONTACT,
        token: token,
        values: values,
        loading: loading,
        intl: intl
    }
}

export const updateContactSuccess = (data) => {
    return {
        type: UPDATE_CONTACT_SUCCESS,
        data: data?.data,
    }
}

export const updateContactPhoneSuccess = (data) => {
    return {
        type: UPDATE_CONTACT_SUCCESS,
        data: data?.data,
    }
}


export const updateCompany = (token, query, loading, intl) => {
    return {
        type: UPDATE_COMPANY,
        token: token,
        query: query,
        loading: loading,
        intl: intl
    }
}





export const updateCompanySuccess = (data) => {
    return {
        type: UPDATE_COMPANY_SUCCESS,
        data: data?.data,
    }
}

export const getCompany = (token, query) => {
    return {
        type: GET_COMPANY,
        token: token,
        query: query
    }
}

export const getCompanySuccess = (data) => {
    return {
        type: GET_COMPANY_SUCCESS,
        data: data?.data,
    }
}

export const refetchContact = () => {
    return {
        type: REFETCH_CONTACT
    }
}
export const deleteContact = (token, query) => {
    return {
        type: DELETE_CONTACT,
        token: token,
        query: query,
        loading: true,
    };
};

export const deleteContactSuccess = (data) => {
    return {
        type: DELETE_CONTACT_SUCCESS,
        data: data?.data,
        loading: false,
    };
};

export const sendInvitation = (token, id) => {
    return {
        type: SEND_INVITATION,
        token: token,
        id: id,
    };
};

export const sendInvitationSuccess = (data) => {
    return {
        type: SEND_INVITATION_SUCCESS,
        data: data?.data,
    };
};

export const getCompanyGroups = (token, id) => {
    return {
        type: GET_COMPANY_GROUPS,
        token: token,
        id: id,
    }
}

export const getCompanyGroupsSuccess = (data) => {
    return {
        type: GET_COMPANY_GROUPS_SUCCESS,
        data: data?.data,
    }
}

export const setContactState = (state) => {
    return {
        type: SET_CONTACT_STATE,
        payload: state,
    }
}

export const setCompanyState = (state) => {
    return {
        type: SET_COMPANY_STATE,
        payload: state,
    }
}

export const setCompanyValueState = (state) => {
    return {
        type: SET_COMPANY_VALUE_STATE,
        payload: state,
    }
}

export const setCompanyValueOrder = (state) => {
    return {
        type: SET_COMPANY_VALUE_ORDER,
        payload: state,
    }
}

export const getCompanyTeam = (token, query) => {
    return {
        type: GET_COMPANY_TEAM,
        token: token,
        query: query
    }
}

export const getCompanyTeamSuccess = (data) => {
    return {
        type: GET_COMPANY_TEAM_SUCCESS,
        data: data?.data,
    }
}

export const getCompanyInvoice = (token, query) => {
    return {
        type: GET_COMPANY_INVOICE,
        token: token,
        query: query
    }
}

export const getCompanyInvoiceSuccess = (data) => {
    return {
        type: GET_COMPANY_INVOICE_SUCCESS,
        data: data?.data,
    }
}

export const getCompanyInvoiceSingle = (token, id) => {
    return {
        type: GET_COMPANY_INVOICE_SINGLE,
        token: token,
        id: id
    }
}

export const getCompanyInvoiceSingleSuccess = (data) => {
    return {
        type: GET_COMPANY_INVOICE_SINGLE_SUCCESS,
        data: data,
    }
}

export const getCompanyInvoiceTicket = (token, form) => {
    return {
        type: GET_COMPANY_INVOICE_TICKET,
        token: token,
        form: form
    }
}

export const getCompanyInvoiceTicketSuccess = (data) => {
    return {
        type: GET_COMPANY_INVOICE_TICKET_SUCCESS,
        data: data,
    }
}

export const getCompanyInvoiceValidate = (token, id) => {
    return {
        type: GET_COMPANY_INVOICE_VALIDATE,
        token: token,
        id: id
    }
}

export const getCompanyInvoiceValidateSuccess = (data) => {
    return {
        type: GET_COMPANY_INVOICE_VALIDATE_SUCCESS,
        data: data,
    }
}

export const getCompanyInvoiceValidateItem = (token, form) => {
    return {
        type: GET_COMPANY_INVOICE_VALIDATE_ITEM,
        token: token,
        form: form
    }
}

export const getCompanyInvoiceValidateItemSuccess = (data) => {
    return {
        type: GET_COMPANY_INVOICE_VALIDATE_ITEM_SUCCESS,
        data: data,
    }
}

export const updateCompanyInvoice = (token, query) => {
    return {
        type: UPDATE_COMPANY_INVOICE,
        token: token,
        query: query
    }
}

export const updateCompanyInvoiceSuccess = (data) => {
    return {
        type: UPDATE_COMPANY_INVOICE_SUCCESS,
        data: data,
    }
}

