// combineReducers come from redux that used for combining reducers that we just made.
import {combineReducers} from 'redux'

// Reducers
import Auth from './Auth'
import Common from './Common'
import List from './List'
import Talent from './Talent'
import File from './File'
import Ref from './Ref'
import EduExpCert from './EduExpCert'
import JobOffer from './JobOffer'
import Search from './Search'
import Company from './Company'
import JobOfferApplication from "./JobOfferApplication"
import Chatgpt from './Chatgpt'
import Portage from './Portage'

export default combineReducers({
    auth: Auth,
    common: Common,
    list: List,
    talent: Talent,
    file: File,
    ref: Ref,
    eduExpCert: EduExpCert,
    jobOffer: JobOffer,
    search: Search,
    company: Company,
    jobOfferApplication: JobOfferApplication,
    chatgpt: Chatgpt,
    portage: Portage,
})