import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationContext } from '../NavigationContext';

const HistoryTracker = () => {
  const location = useLocation();
  const { setHistory } = useContext(NavigationContext);

  useEffect(() => {
    setHistory(prevHistory => [...prevHistory, location.pathname]);
  }, [location]);

  return null;
};

export default HistoryTracker;
