import {
    CREATE_REF, CREATE_REF_SUCCESS, RESET_REF, RESET_REF_SUCCESS, SET_REF_STATE,
    CREATE_AUTO, CREATE_AUTO_SUCCESS, TRACK_STATE_SUCCESS, TRACK_STATE
} from '../action-types'

export const createRef = (token, apiValues, localValues, locale) => {
    return {
        type: CREATE_REF,
        token: token,
        apiValues: apiValues,
        localValues: localValues,
        locale: locale,
    }
}

export const createRefSuccess = (data, localValues) => {
    return {
        type: CREATE_REF_SUCCESS,
        data: data?.ref,
        localValues: localValues,
    }
}

export const autoRef = (token, locale, form) => {
    return {
        type: CREATE_AUTO,
        token: token,
        locale: locale,
        form: form
    }
}

export const autoRefSuccess = (data, localValues) => {
    return {
        type: CREATE_AUTO_SUCCESS,
    }
}
export const resetRef = () => {
    return {
        type: RESET_REF,
    }
}

export const resetRefSuccess = () => {
    return {
        type: RESET_REF_SUCCESS,
    }
}


export const setTalentState = (state) => {
    return {
        type: SET_REF_STATE,
        payload: state,
    }
}

export const track = (token, action) => {
    return {
        type: TRACK_STATE,
        token: token,
        action: action,
    }
}

export const trackSuccess = () => {
    return {
        type: TRACK_STATE_SUCCESS
    }
}
