import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {injectIntl} from "react-intl"
import TextField from '@mui/material/TextField'
import {debounce} from '@mui/material/utils'
import {
    getCertification,
    getCities,
    getEducation,
    getHobby,
    getRefJobTitle,
    getRefUniversity,
    getSkill
} from '../../../redux/actions/List'
import {createRef} from '../../../redux/actions/Ref'
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {capitalizeFirstLetter} from "../../../utils/format";

const AutocompleteCreatable = ({intl, label, tableName, value, onValueChange, error, helperText, disableClearable, showFullResult, resetAfterSelect, valueChangeOnInputChange, fieldSize}) => {
    const _ = require('lodash')
    const dispatch = useDispatch()
    const filter = createFilterOptions()
	const inputRef = useRef(null);

    const [inputValue, setInputValue] = useState('')
    const {token} = useSelector(({auth}) => auth)
    const {cities, job_title, university, education, certification, skill, hobbies} = useSelector(({list}) => list)    

    const [options, setOptions] = useState([])
    const {locale} = useSelector(({common}) => common)
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false)

    //code to fetch options
    const fetch = useMemo(
        () =>
            debounce(
                (
                    request,
                    callback,
                ) => {
                    if (request) {
                        request = request.toLocaleLowerCase()
                    }
          
                    
                    setLoadingAutocomplete(true)
                    switch (tableName) {
                        case 'ref_cities':
                            dispatch(getCities(token, `${request}`, locale))
                            break

                        case 'ref_job_title':
                            dispatch(getRefJobTitle(token, `${request}`, locale))
                            break

                        case 'ref_universities':
                            dispatch(getRefUniversity(token, `${request}`, locale))
                            break

                        case 'ref_educations':
                            dispatch(getEducation(token, `${request}`, locale))
                            break

                        case 'ref_certification':
                            dispatch(getCertification(token, `${request}`, locale))
                            break

                        case 'ref_skills':
                            dispatch(getSkill(token, `${request}`, locale))
                            break

                        case 'ref_hobbies':
                            dispatch(getHobby(token, `${request}`, locale))
                            break
                    }
                },
                500,
            ),
        [],
    );

    //code to set options
    useEffect(() => {
        let newOptionsList = []
        let results = []

        switch (tableName) {
            case 'ref_cities':
                newOptionsList = [...cities]
                break

            case 'ref_job_title':
                newOptionsList = [...job_title]
                break

            case 'ref_universities':
                newOptionsList = [...university]
                break

            case 'ref_educations':
                newOptionsList = [...education]
                break

            case 'ref_certification':
                newOptionsList = [...certification]
                break

            case 'ref_skills':
                newOptionsList = [...skill]
                break

            case 'ref_hobbies':
                newOptionsList = [...hobbies]
                break
        }
        if (newOptionsList?.length > 0) {
            results = newOptionsList.filter((option) => {
                const optionName = option?.name?.toLocaleLowerCase()

                if (showFullResult && showFullResult === true) {
                    let startingResults = optionName?.startsWith(inputValue.toLocaleLowerCase()) && (optionName !== '');
                    return optionName !== ''
                }

                if (inputValue != null) {
                    return optionName?.startsWith(inputValue.toLocaleLowerCase()) && (optionName !== '')
                }
            }).sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                // Check if the input text is a substring of each name
                const includesA = nameA.startsWith(inputValue.toLocaleLowerCase());
                const includesB = nameB.startsWith(inputValue.toLocaleLowerCase());

                if (includesA && !includesB) {
                    return -1;
                } else if (!includesA && includesB) {
                    return 1;
                } else {
                    // If both include the input text or none of them do, sort alphabetically
                    return nameA.localeCompare(nameB);
                }
            })
        }

        setOptions(results)
        setLoadingAutocomplete(false)
    }, [cities, job_title, university, education, certification, skill, hobbies, value])


    useEffect(() => {
        if (inputValue === '') {
            setOptions(value ? [value] : [])
            return undefined
        }

        fetch(inputValue, () => {
        })
    }, [inputValue])

    useEffect(() => {
        if (value !== inputValue) {
            setInputValue(value)
        }
    }, [value])


	const handleInputChange = (event, newInputValue) => {
		const cursorPosition = inputRef.current.selectionStart;
		setInputValue(newInputValue);
		if (valueChangeOnInputChange) {
		  onValueChange(newInputValue);
		}
		setTimeout(() => {
		  if (inputRef.current) {
			inputRef.current.selectionStart = cursorPosition;
			inputRef.current.selectionEnd = cursorPosition;
		  }
		}, 0);
	  };	

    return (
		<Autocomplete
		disableClearable={disableClearable}
		fullWidth
		value={capitalizeFirstLetter(inputValue) || ""}
		selectOnFocus
		handleHomeEndKeys
		options={options}
		getOptionLabel={(option) => {
		  if (typeof option === "string") {
			return option;
		  }
		  return capitalizeFirstLetter(option?.name);
		}}
		renderOption={(props, option) => {
		  return <li {...props}>{capitalizeFirstLetter(option?.name)}</li>;
		}}
		freeSolo
		loading={loadingAutocomplete}
		loadingText={`${intl.formatMessage({ id: "search.pending" })}...`}
		renderInput={(params) => (
		  <TextField
			{...params}
			inputRef={inputRef}
			label={label}
			error={error}
			helperText={helperText}
			size={fieldSize}
		  />
		)}
		autoComplete
		includeInputInList
		onChange={(event, newValue) => {
		  setInputValue(newValue?.name || "");
		}}
		onBlur={() => {
		  onValueChange(inputValue);
		  if (resetAfterSelect) {
			setInputValue("");
		  }
		}}
		onInputChange={handleInputChange}
		onKeyDown={(event) => {
		  if (event.key === "Enter") {
			onValueChange(inputValue);
			if (resetAfterSelect) {
			  setInputValue("");
			}
		  }
		}}
	  /> 
    )
}
export default injectIntl(AutocompleteCreatable)
