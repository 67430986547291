import {
    SET_COMPANY_STATE,
    GET_CONTACT_SUCCESS,
    UPDATE_CONTACT_SUCCESS,
    GET_COMPANY_SUCCESS,
    UPDATE_COMPANY_SUCCESS,
    REFETCH_CONTACT,
    DELETE_CONTACT_SUCCESS, GET_COMPANY_GROUPS_SUCCESS,
    GET_MODULE_GROUPS_SUCCESS, SET_CONTACT_STATE, SET_COMPANY_VALUE_STATE,
    SET_COMPANY_VALUE_ORDER, GET_COMPANY_TEAM_SUCCESS, GET_COMPANY_INVOICE_SUCCESS,
    UPDATE_COMPANY_INVOICE_SUCCESS, GET_COMPANY_INVOICE_SINGLE_SUCCESS, GET_COMPANY_INVOICE_TICKET_SUCCESS,
    GET_COMPANY_INVOICE_VALIDATE_SUCCESS, GET_COMPANY_INVOICE_VALIDATE_ITEM_SUCCESS
} from '../action-types'

const INIT_STATE = {
    contact: null,
    company: null,
    reupdate_contact: 0,
    groups : null,
    module: null,
    team : [],
    invoices : [],
    invoice: [],
    company_invoice: [],
    company_invoice_tickets: [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CONTACT_SUCCESS: {
            return {
                ...state,
                contact: action?.data?.contactById,
                company: action?.data?.contactById?.company
            }
        }

        case UPDATE_CONTACT_SUCCESS: {
            let contact = action?.data?.upsertContact || state.contact
            return {
                ...state,
                contact: contact,
                company: contact?.company,
            }
        }

        case GET_COMPANY_TEAM_SUCCESS: {
            return {
                ...state,
                team: action?.data?.talentByEmployedId?.data
            }
        }

        case UPDATE_COMPANY_SUCCESS: {
            let company = action?.data?.upsertCompany || state.company
            return {
                ...state,
                company: company,
            }
        }

        case GET_COMPANY_SUCCESS: {
            return {
                ...state,
                company: action?.data?.companyById
            }
        }

        case REFETCH_CONTACT: {
            return {
                ...state,
                reupdate_contact: state.reupdate_contact + 1
            }
        }

        case DELETE_CONTACT_SUCCESS: {
            let contact = action?.data?.deleteContact || state.contact;
            return {
                ...state,
                company: contact?.company,
                loadAfterQuery: true,
            };
        }

        case GET_COMPANY_GROUPS_SUCCESS: {
            return {
                ...state,
                groups: action?.data?.groupByCompanyId,
            }
        }

        case SET_CONTACT_STATE: {
            let contactCopy = {...state.contact}
            contactCopy[action?.payload?.key] = action?.payload?.value

            return {...state, contact: contactCopy}
        }

        case SET_COMPANY_STATE: {
            let companyCopy = {...state.company}
            companyCopy[action?.payload?.key] = action?.payload?.value

            return {...state, company: companyCopy}
        }

        case SET_COMPANY_VALUE_STATE: {
            let company = {...state.company}

            let companyValues = state.company.values || []
            let newValue = action?.payload?.value
            let newLevel = action?.payload?.level

            let toBeUpdatedValue = companyValues.find((value) => value.id === newValue.id)
            if (toBeUpdatedValue) {
                toBeUpdatedValue.pivot.level = newLevel
            } else {
                companyValues.push({
                    id: newValue.id,
                    name: newValue.name,
                    type: newValue.type,
                    pivot: {
                        level: newLevel
                    }
                })
            }

            let newCompany= {
                ...company,
                values: companyValues
            }

            return {...state, company: newCompany}
        }

        case SET_COMPANY_VALUE_ORDER: {
            let company = {...state.company}
            let newCompany= {
                ...company,
                value_orders: action.payload
            }

            return {...state, company: newCompany}
        }

        case GET_COMPANY_INVOICE_SUCCESS: {
            return {
                ...state,
                invoices: action?.data?.invoicesByCompany?.data,
            }
        }

        case UPDATE_COMPANY_INVOICE_SUCCESS: {
            return {
                ...state,
                invoices: action?.data?.invoices
            }
        }

        case GET_COMPANY_INVOICE_SINGLE_SUCCESS: {
            return {
                ...state,
                invoice: action?.data?.invoice,
                company_invoice: action?.data?.company_invoice,
                company_invoice_tickets: action?.data?.company_invoice_tickets
            }
        }

        case GET_COMPANY_INVOICE_TICKET_SUCCESS: {
            return {
                ...state,
                invoice: action?.data?.invoice,
                company_invoice: action?.data?.company_invoice,
                company_invoice_tickets: action?.data?.company_invoice_tickets
            }
        }

        case GET_COMPANY_INVOICE_VALIDATE_SUCCESS: {
            return {
                ...state,
                invoice: action?.data?.invoice,
                company_invoice: action?.data?.company_invoice,
                company_invoice_tickets: action?.data?.company_invoice_tickets
            }
        }

        case GET_COMPANY_INVOICE_VALIDATE_ITEM_SUCCESS: {
            return {
                ...state,
                invoice: action?.data?.invoice,
                company_invoice: action?.data?.company_invoice,
                company_invoice_tickets: action?.data?.company_invoice_tickets
            }
        }

        default:
            return state
    }
}
