import React, {useEffect, useState} from 'react'
import {injectIntl} from "react-intl"
import {useLocation, useNavigate} from 'react-router-dom'
import {autoLogin, getCurrentUser} from "../../redux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";

const Autologin = ({intl}) => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    let location = useLocation()
    let fromTalent = location.state?.from?.pathname || '/talent/dashboard'
    let fromCompany = location.state?.from?.pathname || '/company/dashboard'
    const {token, user} = useSelector(({auth}) => auth)
    const [uid, setUid] = useState(null)

    useEffect(() => {
        if(uid === null) {
            const urlParams = new URLSearchParams(window.location.search);
            const urlUid = urlParams.get('uid')
            setUid(urlUid)
        }
    },[])

    useEffect(() => {
        if(uid) {
            dispatch(autoLogin(uid))
        }
    },[uid])

    useEffect(() => {
        if (token && !user) {
            dispatch(getCurrentUser(token))
        }
    }, [token, user])

    useEffect(() => {
        if (token && user?.type) {
            if (user.type == 'talent') {
                navigate(fromTalent, {replace: true})
            }
            if (user.type == 'company') {
                navigate(fromCompany, {replace: true})
            }
        }
    }, [user, token, fromTalent, fromCompany, navigate])


    return (
        <div className="login-container">
            <header className="py-2 flex flex-col space-y-5 justify-center items-center">
                <img src={require('./../../assets/images/logo.png')} className="w-96" alt="Logo de Talenteum"/>
            </header>

            <main className="my-8 flex justify-center">
                <section className="shadow-xxl rounded-4xl p-5">
                    <h3 className={'text-secondary text-xl'}>
                        {intl.formatMessage({id:'redirec.progress'})}...
                    </h3>
                </section>
            </main>
        </div>
    )
}

export default injectIntl(Autologin)
