import {
    AUTO_LOGIN_SUCCESS,
    FETCH_EXPIRED,
    GET_CURRENT_USER_SUCCESS,
    REGISTER_RESEND_EMAIL_SUCCESS,
    REGISTER_USER, REGISTER_USER_LINKEDIN_SUCCESS,
    REGISTER_USER_SUCCESS,
    RESET_PWD_SUCCESS,
    SEND_OTP,
    SEND_OTP_SUCCESS,
    SET_AUTH_STATE,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    VALIDATE_OTP,
    VALIDATE_OTP_SUCCESS,
} from '../action-types'


// Define your state here
const initialState = {
    loading: false,
    token: JSON.parse(localStorage.getItem('breedj_user_token')) || null,
    user: null,
    register_success: null,
    register_linked_success: null,
    send_otp_success: false,
    validate_otp_success: false,
    resend_email_success: 0,
}

// This export default will control your state for your application
export default (state = initialState, action) => {
    switch (action.type) {
        case SIGNIN_USER_SUCCESS:
            localStorage.setItem('breedj_user_token', JSON.stringify(action.payload.token))

            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                loading: false,
                register_success: null
            }

        case GET_CURRENT_USER_SUCCESS:

            return {
                ...state,
                user: action?.payload?.user,
                loading: false
            }

        case RESET_PWD_SUCCESS:
            return {
                ...state,
                user: action?.payload?.user,
                loading: false
            }

        case SIGNOUT_USER:
        case FETCH_EXPIRED:
            localStorage.removeItem('breedj_user_token')

            return {
                ...state,
                token: null,
                user: null,
                loading: false
            }

        case SIGNOUT_USER_SUCCESS:
            localStorage.removeItem('breedj_user_token')

            return {
                ...state,
                token: null,
                user: null,
                loading: false
            }

        case REGISTER_USER:
            return {
                ...state,
                register_success: null,
            }

        case REGISTER_USER_LINKEDIN_SUCCESS:
            return {
                ...state,
                register_linked_success: action.payload,
                loading: false
            }

        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                register_success: action.payload,
                loading: false
            }

        case REGISTER_RESEND_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                resend_email_success: state.resend_email_success+1
            }

        case SEND_OTP:
            return {
                ...state,
                send_otp_success: false,
            }

        case SEND_OTP_SUCCESS:
            return {
                ...state,
                send_otp_success: true,
                loading: false
            }

        case VALIDATE_OTP:
            return {
                ...state,
                validate_otp_success: false,
            }

        case VALIDATE_OTP_SUCCESS:
            return {
                ...state,
                validate_otp_success: true,
                user: action?.payload?.user,
                loading: false
            }

        case SET_AUTH_STATE: {
            return {
                ...state,
                ...action?.payload
            }
        }
        // Return default state if you didn't match any case
        default:
            return state
    }
}