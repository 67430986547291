import {axiosInstance, axiosInstanceGraphQL} from '../../axios/axios'

export const getSearchRequest = async (token) =>
    await axiosInstance.get('/api/search', {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const saveSearchRequest = async (token, form) =>
    await axiosInstance.post('/api/search/store', form, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const deleteSearchRequest = async (token, id) =>
    await axiosInstance.post('/api/search/delete/' + id, {}, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const searchActionRequest = async (token, form) =>
    await axiosInstanceGraphQL.post('/api/search/action', form, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const searchMatchRequest = async (token, id) =>
    await axiosInstanceGraphQL.get('/api/search/match/' + id,  {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })