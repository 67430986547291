import {
    SET_EDU_EXP_CERT_STATE,
    UPDATE_EDU_EXP_CERT, UPDATE_EDU_EXP_CERT_SUCCESS,
    UPDATE_SKILL_LANG_HOBBY,UPDATE_SKILL_LANG_HOBBY_SUCCESS
} from '../action-types'

export const updateEduExpCert = (token, apiValues) => {
    return {
        type: UPDATE_EDU_EXP_CERT,
        token: token,
        apiValues: apiValues,
    }
}

export const updateEduExpCertSuccess = (data) => {
    return {
        type: UPDATE_EDU_EXP_CERT_SUCCESS,
        data: data,
    }
}

export const updateSkillLangHobby = (token, apiValues) => {
    return {
        type: UPDATE_SKILL_LANG_HOBBY,
        token: token,
        apiValues: apiValues,
    }
}

export const updateSkillLangHobbySuccess = (data) => {
    return {
        type: UPDATE_SKILL_LANG_HOBBY_SUCCESS,
        data: data,
    }
}

export const setEduExpCertState = (state) => {
    return {
        type: SET_EDU_EXP_CERT_STATE,
        payload: state,
    }
}
